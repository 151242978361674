import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import LogoBig from "images/logo-big.svg"
import { useTransform, useViewportScroll, motion } from "framer-motion"
const cx = classNames.bind(styles)

const BottomSection = ({ data }) => {
  const content = data.sanityHome.section6
  const { scrollYProgress } = useViewportScroll()
  const ref = React.useRef(null)
  const [vh, setVh] = React.useState(null)
  const [start, setStart] = React.useState(null)
  const [end, setEnd] = React.useState(null)

  React.useLayoutEffect(() => {
    setVh(
      Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )
    )
    setStart((document.body.clientHeight - vh * 3) / document.body.clientHeight)
    setEnd((document.body.clientHeight - vh) / document.body.clientHeight)
  })

  const transform1 = useTransform(scrollYProgress, [start, end], [0, 1])
  const transform2 = useTransform(scrollYProgress, [end, 1], [0, 1])

  return (
    <section className={cx("wrapper")}>
      <div className={cx("imageWrapper")} ref={ref}>
        <BackgroundImage
          preserveStackingContext
          Tag="div"
          fluid={content.image.asset.fluid}
          backgroundColor={`#040e18`}
          className={cx("image")}
        ></BackgroundImage>
        <motion.div
          style={{ opacity: transform1 }}
          className={cx("tint")}
        ></motion.div>
        <div className={cx("logoWrapper")}>
          <motion.div style={{ opacity: transform2 }}>
            <LogoBig className={cx("logo")} />
          </motion.div>
        </div>
      </div>
    </section>
  )
}

BottomSection.propTypes = {}

export default BottomSection
