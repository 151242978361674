import React from "react"
/*
  Takes an optional component ref (or returns a new one)
  and returns the ref, the scroll `start` and `end` percentages
  that are relative to the total document progress.
*/
export default function useRefScrollProgress(inputRef) {
  let ref = React.useRef()
  ref = inputRef || ref
  const [start, setStart] = React.useState(null)
  const [end, setEnd] = React.useState(null)
  const [startAtTop, setStartAtTop] = React.useState(null)
  const [endAtViewportEnd, setEndAtViewportEnd] = React.useState(null)
  React.useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
    const rect = ref.current.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const offsetTop = rect.top + scrollTop
    const offsetViewportBottom = offsetTop - vh
    setStart(offsetTop / document.body.clientHeight)
    setEnd((offsetTop + rect.height) / document.body.clientHeight)
    setStartAtTop(offsetViewportBottom / document.body.clientHeight)
    setEndAtViewportEnd((offsetTop + rect.height) / document.body.clientHeight)
  })
  return { ref, start, end, startAtTop, endAtViewportEnd }
}
