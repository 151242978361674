import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import BackgroundImage from "gatsby-background-image"
import PortableText from "components/PortableText"
import Woods from "images/woods-icon.svg"
import Waves from "images/waves-icon.svg"
import useRefScrollProgress from "hooks/useRefScrollProgress"
import { useTransform, useViewportScroll, motion } from "framer-motion"

const cx = classNames.bind(styles)

const ShowcaseSection = ({ data }) => {
  const content = data.sanityHome.section3
  const content2 = data.sanityHome.section4

  const { scrollYProgress } = useViewportScroll()
  const {
    ref: ref1,
    startAtTop: start1,
    endAtViewportEnd: end1,
  } = useRefScrollProgress()
  const {
    ref: ref2,
    startAtTop: start2,
    endAtViewportEnd: end2,
  } = useRefScrollProgress()
  const transform1 = useTransform(scrollYProgress, [start1, end1], [-80, 80])
  const transform2 = useTransform(scrollYProgress, [start2, end2], [-80, 80])

  return (
    <section className={cx("wrapper")}>
      <div className={cx("backgroundLeft")} />
      <div className={cx("backgroundRight")} />

      <div className={cx("row", "-first")}>
        <div className={cx("content")}>
          <div className={cx("content-inner")}>
            <Woods className={cx("content-icon")} />
            <h4 className={cx("content-header")}>{content.contentTitle}</h4>
            <div className={cx("content-text")}>
              {content._rawContent && (
                <PortableText blocks={content._rawContent} />
              )}
            </div>
            <Link to="/sklypai" className={cx("content-link")}>
              Rinktis
            </Link>
          </div>
        </div>

        <motion.div
          style={{ "--bgOffset": transform1 }}
          className={cx("imageWrap")}
          ref={ref1}
        >
          <BackgroundImage
            className={cx("image")}
            preserveStackingContext
            Tag="div"
            fluid={content.image.asset.fluid}
            backgroundColor={`#fff`}
          />
        </motion.div>
      </div>
      <div className={cx("row", "-second")}>
        <motion.div
          style={{ "--bgOffset": transform2 }}
          className={cx("imageWrap")}
          ref={ref2}
        >
          <BackgroundImage
            className={cx("image")}
            preserveStackingContext
            Tag="div"
            fluid={content2.image.asset.fluid}
            backgroundColor={`#fff`}
          />
        </motion.div>

        <div className={cx("content")}>
          <div className={cx("content-inner")}>
            <Waves className={cx("content-icon")} />
            <h4 className={cx("content-header")}>{content2.contentTitle}</h4>
            <div className={cx("content-text")}>
              {content2._rawContent && (
                <PortableText blocks={content2._rawContent} />
              )}
            </div>
            <Link to="/sklypai" className={cx("content-link")}>
              Rinktis
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

ShowcaseSection.propTypes = {}

export default ShowcaseSection
