import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import useProgress from "hooks/useProgress"
import Img from "gatsby-image"
import { wrap } from "popmotion"
import { motion, AnimatePresence } from "framer-motion"
import ArrowLeft from "images/arrow-left.svg"
import Figure from "components/Figure"

const cx = classNames.bind(styles)

let SLIDE_DURATION = 8000
const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 50 : -50,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 50 : -50,
      opacity: 0,
    }
  },
}

// const Image = ({ isCurrent, position, image, id, title }) => {
//   const number = ("0" + position.current).slice(-2)
//   const all = ("0" + position.all).slice(-2)

//   return (
//     <motion.div
//       className={cx("imageWrapper", { "-active": isCurrent })}
//       aria-hidden={!isCurrent}
//       tabIndex="-1"
//     >
//       <Img aria-labelledby={id} className={cx("image")} fluid={image.fluid} />
//       <div className={cx("caption")}>
//         <span className={cx("caption-count")}>
//           {number} / {all}
//         </span>
//         <span className={cx("caption-text")}>{title}</span>
//       </div>
//     </motion.div>
//   )
// }

const Placeholder = ({ isCurrent, image, id }) => {
  return (
    // <Img
    //   aria-hidden={!isCurrent}
    //   tabIndex="-1"
    //   aria-labelledby={id}
    //   className={cx("placeholder", { "-active": isCurrent })}
    //   style={{ position: "absolute" }}
    //   // fluid={image.fluid}
    //   fluid={{ ...image.fluid, aspectRatio: 16 / 9 }}
    // />
    <Figure
      aria-hidden={!isCurrent}
      tabIndex="-1"
      aria-labelledby={id}
      className={cx("placeholder", { "-active": isCurrent })}
      style={{ position: "absolute" }}
      node={image}
      // fluid={image.fluid}
      // fluid={{ ...image.fluid, aspectRatio: 16 / 9 }}
    />
  )
}

const ProgressBar = ({ animate, time }) => {
  const progress = useProgress(animate, time)

  return (
    <div className={cx("ProgressBar")}>
      <div style={{ width: `${progress * 100}%` }} />
    </div>
  )
}

const Slider = ({ slides }) => {
  let [state, dispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "NEXT":
        case "PROGRESS":
          return {
            ...state,
            currentIndex: (state.currentIndex + 1) % slides.length,
          }
        case "PREV":
          return {
            ...state,
            currentIndex:
              (state.currentIndex - 1 + slides.length) % slides.length,
          }
        default:
          return state
      }
    },
    {
      currentIndex: 0,
      isPlaying: true,
    }
  )

  const [isPlaying, setIsPlaying] = React.useState(true)
  const [[page, direction], setPage] = React.useState([0, 0])
  const imageIndex = wrap(0, slides.length, page)
  const placeholderIndex = wrap(0, slides.length, page - 1)
  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }

  // const [slideContent, setSlideContent] = React.useState(
  //   slides[state.currentIndex].content
  // )

  // React.useEffect(() => {
  //   setSlideContent(slides[state.currentIndex].content)
  // }, [state.currentIndex])

  React.useEffect(() => {
    if (isPlaying) {
      let timeout = setTimeout(() => {
        paginate(1)
      }, SLIDE_DURATION)
      return () => clearTimeout(timeout)
    }
  }, [page, isPlaying])

  return (
    <div className={cx("slider")}>
      <div className={cx("placeholdersWrapper")}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className={cx("placeholderWrapper")}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            {/* <Img
              className={cx("placeholder")}
              // style={{ position: "absolute" }}
              // fluid={{
              //   ...slides[placeholderIndex].asset.fluid,
              //   aspectRatio: 16 / 9,
              // }}
              fluid={slides[placeholderIndex].asset.fluid}
            /> */}
            <Figure
              className={cx("placeholder")}
              // fluid={slides[imageIndex].asset.fluid}
              node={slides[placeholderIndex]}
            />
          </motion.div>
        </AnimatePresence>
      </div>
      <div className={cx("imagesWrapper")}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className={cx("imageWrapper")}
            key={page}
            src={slides[imageIndex]}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <Figure className={cx("image")} node={slides[imageIndex]} />
            {/* <Img
                className={cx("image")}
              fluid={slides[imageIndex].asset.fluid}
            /> */}
          </motion.div>
        </AnimatePresence>
      </div>
      <div className={cx("content")}>
        {/* <div className={cx("text")}>{slides[imageIndex].content}</div> */}

        <div className={cx("controls")}>
          <button
            className={cx("control")}
            aria-label="Previous Slide"
            onClick={() => paginate(-1)}
          >
            <ArrowLeft className={cx("left")} />
          </button>
          <button
            className={cx("control")}
            aria-label="Next Slide"
            onClick={() => paginate(1)}
          >
            <ArrowLeft className={cx("right")} />
          </button>
          <div className={cx("caption")}>
            <span className={cx("caption-count")}>
              {imageIndex + 1} / {slides.length}
            </span>
          </div>
        </div>
      </div>
      <ProgressBar
        key={page + isPlaying}
        time={SLIDE_DURATION}
        animate={isPlaying}
      />
    </div>
  )
}

export default Slider
