import React from "react"
import SEO from "components/Seo"
import Helmet from "react-helmet"
import {
  TopSection,
  AboutSection,
  ShowcaseSection,
  SliderSection,
  BottomSection,
} from "components/Homepage"
import classNames from "classnames/bind"
import styles from "./home.module.css"

const cx = classNames.bind(styles)

const IndexPage = ({ data }) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "page--home",
        }}
      />
      <SEO title={data.sanityHome.title} />
      <div className={cx("sections-wrapper")}>
        <TopSection data={data} />
        <AboutSection data={data} />
        <ShowcaseSection data={data} />
        <SliderSection data={data} />
      </div>
      <BottomSection data={data} />
    </>
  )
}

// Inside your component
export const query = graphql`
  {
    allSanityLandplot(filter: { status: { ne: "Išjungtas" } }) {
      totalCount
    }
    sanityHome {
      id
      title
      section1 {
        content
        image {
          asset {
            url
            _id
            fluid(maxWidth: 2500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      section2 {
        title
        subtitle
        contentTitle
        _rawContent(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            url
            _id
            fluid(maxWidth: 2500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      section3 {
        contentTitle
        _rawContent(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            url
            _id
            fluid(maxWidth: 2500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      section4 {
        contentTitle
        _rawContent(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            url
            _id
            fluid(maxWidth: 2500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      section5 {
        title
        subtitle
        images {
          caption
          content
          asset {
            url
            _id
            fluid(maxWidth: 2500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      section6 {
        image {
          asset {
            url
            _id
            fluid(maxWidth: 2500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
