import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import classNames from "classnames/bind"
import PortableText from "components/PortableText"
import styles from "./styles.module.css"
import useRefScrollProgress from "hooks/useRefScrollProgress"
import { useTransform, useViewportScroll, motion } from "framer-motion"

const cx = classNames.bind(styles)

const AboutSection = ({ data }) => {
  const content = data.sanityHome.section2
  const { scrollYProgress } = useViewportScroll()
  const { ref, startAtTop, endAtViewportEnd } = useRefScrollProgress()
  const transform = useTransform(
    scrollYProgress,
    [startAtTop, endAtViewportEnd],
    [-120, 120]
  )

  return (
    <section className={cx("wrapper")}>
      <div className={cx("backgroundRight")} />
      <h3 className={cx("title")}>
        <span className={cx("title-big")}>{content.title}</span>
        <span className={cx("title-small")}>{content.subtitle}</span>
      </h3>

      <div className={cx("blocks")}>
        <motion.div
          style={{ "--bgOffset": transform }}
          className={cx("imageWrapper")}
          ref={ref}
        >
          <BackgroundImage
            className={cx("image")}
            preserveStackingContext
            Tag="div"
            fluid={content.image.asset.fluid}
            backgroundColor={`#fff`}
          />
        </motion.div>

        <div className={cx("content")}>
          <h4>{content.contentTitle}</h4>
          {content._rawContent && <PortableText blocks={content._rawContent} />}
          <Link to="/sklypai" className={cx("choose")}>
            <div className={cx("choose-link")}>Rinktis</div>
            <div className={cx("choose-text")}>
              <div className={cx("choose-textCount")}>
                {data.allSanityLandplot.totalCount}
              </div>
              <div className={cx("choose-textLabel")}>sklypai</div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

AboutSection.propTypes = {}

export default AboutSection
