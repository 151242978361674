import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import Header from "components/Header"
import useRefScrollProgress from "hooks/useRefScrollProgress"
import { useTransform, useViewportScroll, motion } from "framer-motion"
import { isIOS } from "react-device-detect"

const cx = classNames.bind(styles)

const TopSection = ({ data }) => {
  // const topSectionBackground = data.sanityHome.section1.image.asset.fluid
  const topSectionBackgroundUrl = data.sanityHome.section1.image.asset.url
  const { scrollYProgress } = useViewportScroll()
  const { ref, startAtTop, endAtViewportEnd } = useRefScrollProgress()
  const transform = useTransform(
    scrollYProgress,
    [0, endAtViewportEnd],
    [0, -200]
  )

  React.useEffect(() => {
    if (isIOS) {
      document.querySelector("html").classList.add("-ios")
    }
  }, [])

  return (
    <motion.section
      className={cx("wrapper")}
      style={{ "--bgOffset": transform }}
      ref={ref}
    >
      <div className={cx("headerWrapper")}>
        <Header inContent />
      </div>

      <div
        className={cx("backgroundImage")}
        style={{ backgroundImage: `url(/images/sklypai-full.jpg)` }}
        // style={{ backgroundImage: `url(${topSectionBackgroundUrl})` }}
      ></div>
      {/* <BackgroundImage
        preserveStackingContext
        Tag="div"
        fluid={topSectionBackground}
        backgroundColor={`#040e18`}
        className={cx("backgroundImage")}
      /> */}
      <div className={cx("backgroundShade")} />
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <h2 className={cx("title")}>
            <div className={cx("title-firstLine")}>Bebrusų</div>
            <div className={cx("title-secondLine")}>slėnis</div>
          </h2>
          <p className={cx("text")}>{data.sanityHome.section1.content}</p>
          <Link to="/sklypai" className={cx("link")}>
            Rinktis sklypą
          </Link>
        </div>
      </div>
    </motion.section>
  )
}

TopSection.propTypes = {}

export default TopSection
