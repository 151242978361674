import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import Slider from "components/Slider"
import { client } from "lib/helpers"
// import Figure from "components/Figure"

const cx = classNames.bind(styles)

const SliderSection = ({ data }) => {
  const images = data?.sanityHome?.section5.images.map(image => ({
    ...image,
    asset: {
      ...image.asset,
      _ref: image.asset._id,
    },
  }))
  const [imgs, setImgs] = React.useState(images)
  const content = data.sanityHome.section5
  const query = `
    *[_type=="home"][0]{
      section5 {
        images
      }
    }
  `

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.fetch(query)

        if (res && res.section5.images.length) {
          setImgs(res.section5.images)
        }
      } catch (error) {
        console.error("err: ", error.message)
      }
    }

    fetchData()
  }, [])

  return (
    <section className={cx("wrapper")}>
      <div className={cx("backgroundBottom")} />
      <h3 className={cx("title")}>
        <span className={cx("title-big")}>{content.title}</span>
        <span className={cx("title-small")}>{content.subtitle}</span>
      </h3>
      <Slider slides={imgs} />
      {/* <Slider slides={content.images} /> */}
    </section>
  )
}

SliderSection.propTypes = {}

export default SliderSection
